import React from 'react'
import cuate from '../images/cuate.png'
import './style.css'

function Confirmation() {
  return (
    <section className='confimation'>
        <div className='container'>
          <div className='wrapp'>
          <h3>Votre réservation</h3>
            <p>votre réservation a été confirmée</p>
            <img src={cuate} />
          </div>
            <button type="button" class="btn btn-secondary btn-main">De retour à la maison</button>
        </div>
    </section>
  )
}

export default Confirmation