// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    font-family: "Syne", sans-serif;
 
}

.btn-main{
    background-image: linear-gradient(to right, #C43CAB, #F87E4B);
    border-radius: 25px;
    padding: 9px 20px;
    border: 0;
}

.btn-main:focus{
    box-shadow: unset !important;
}

.btn-outline-main{
    border: 2px solid #dc5c81 !important;
    color: #000;
    border-radius: 20px;
    padding: 7px 22px;
    margin-left: 14px;
}

.btn-outline-main:hover{
    background-image: linear-gradient(to right, #C43CAB, #F87E4B);
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;;AAEnC;;AAEA;IACI,6DAA6D;IAC7D,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,6DAA6D;AACjE","sourcesContent":["body{\n    font-family: \"Syne\", sans-serif;\n \n}\n\n.btn-main{\n    background-image: linear-gradient(to right, #C43CAB, #F87E4B);\n    border-radius: 25px;\n    padding: 9px 20px;\n    border: 0;\n}\n\n.btn-main:focus{\n    box-shadow: unset !important;\n}\n\n.btn-outline-main{\n    border: 2px solid #dc5c81 !important;\n    color: #000;\n    border-radius: 20px;\n    padding: 7px 22px;\n    margin-left: 14px;\n}\n\n.btn-outline-main:hover{\n    background-image: linear-gradient(to right, #C43CAB, #F87E4B);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
