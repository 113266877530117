import React from 'react'
import checkinternal from "../../images/checkinternal.png"
import { useNavigate } from 'react-router-dom';
import  "../style.css"

function Check_internal() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
    <section className='checkinternal'>
            <div className='container'>
       <h4>Préparez-vous aux questions de l'examen</h4>
        <div className='back-btn mt-3'>
        <button type="button" onClick={goBack} class="btn btn-secondary btn-main"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white"/>
        </svg>
        Retour</button>
        </div>
        <div className='text-wraps'>
        <div className='top-title'>
                    <img src={checkinternal} />
                    <h4>Contrôles internes</h4>
                </div>
                <p>Depuis le 1er janvier 2018, les deux questions de vérifications prévues à l’examen pratique ont été complétées par une troisième question concernant « Les premiers secours ». Ainsi, ces 3 nouvelles questions seront liées et complémentaires : Une question de vérification intérieure ou extérieure, une question de sécurité routière et une question de premiers secours. Les trois thèmes spécifiques aux questions sont les suivants :

</p>
<ul>
    <li>les vérifications intérieures</li>
    <li>les vérifications extérieures</li>
    <li>les premiers secours</li>
</ul>
<p>A chaque question de vérification est liée une question de sécurité routière. Chaque question rapportera 1 point au candidat. L’objectif principal de ces vérifications est de sensibiliser le candidat sur les dangers de la route, lui donner les bons comportements et réflexes à adopter pour réduire l’accidentalité sur les routes.</p>
<hr className='divide-line'></hr>
<div className='questions'>
    <div>
    <h5>1. Effectuez un appel lumineux.</h5>
    <p>Tirez la commande des feux vers vous ou passez plusieurs fois de suite des feux de croisement aux feux de route et inversement.

</p>              
    </div>
    <div>
    <h5>2. Vérifiez la présence du certificat d'immatriculation du véhicule (carte grise).</h5>
    <p>Il faut simplement montrer la carte grise à l'inspecteur. Demandez à votre enseignant où elle se trouve avant l'examen (boîte à gants, porte avant gauche/droite etc.)

</p>              
    </div>
    <div>
    <h5>3. Actionnez la commande du clignotant droit et montrez le voyant correspondant sur le tableau de bord.</h5>
    <p>Rien de plus à faire que d'actionner le clignotant droit, et de montrer le voyant vert correspondant sur le tableau de bord !

</p>              
    </div>
    <div>
    <h5>4. Que vous indique un voyant de couleur verte ?</h5>
    <p>Cela correspond au fonctionnement des feux de position, de croisement, des clignotants, ou de brouillard avant.
</p>              
    </div>
    <div>
    <h5>5. Actionnez la commande du clignotant gauche et montrez le voyant correspondant sur le tableau de bord.</h5>
    <p>Il suffit d'actionner le clignotant gauche, et de montrer à l'inspecteur le voyant vert correspondant sur le tableau de bord.
</p>              
    </div>
    <div>
    <h5>6. Mettez le système de chauffage sur la position "air froid"</h5>
    <p>La manipulation est très simple : il suffit de tourner la manette rotative permettant de régler la température de l’air du côté bleu.
</p>              
    </div>
    <div>
    <h5>7. Montrez comment régler la hauteur de l'appui-tête du siège conducteur.</h5>
    <p>Faire monter et descendre l'appuie-tête du siège conducteur (appuyer éventuellement sur le bouton de blocage).
</p>              
    </div>
    <div>
    <h5>8. Montrer l'emplacement du dispositif de réglage intérieur des blocs optiques.</h5>
    <p>Une molette permet de corriger la hauteur du faisceau (des feux). Elle se règle surtout en fonction de la charge du véhicule : plus la charge est importante à l’arrière, plus il faut abaisser les feux de manière à ne pas éblouir les autres usagers de la route. Voilà à quoi ressemble la molette de réglage, elle se trouve généralement près du volant.
</p>              
    </div>
    <div>
    <h5>9. Montrer ou indiquer où se trouve la boîte à fusibles.</h5>
    <p>La boîte à fusibles se trouve généralement en bas à gauche du volant (sous la planche de direction). Elle n’est plus visible sur les véhicules récents : demandez des conseils à votre moniteur sur son positionnement à l’intérieur de la planche de bord pour ne pas avoir de surprise le jour du permis.
</p>              
    </div>
    <div>
    <h5>10. Allumez les feux de croisement et montrez le voyant correspondant sur le tableau de bord.</h5>
    <p>Il suffit de tourner la manette à gauche du volant jusqu’au symbole correspondant aux feux de croisement, et de montrer le symbole suivant sur le tableau de bord. Dans la plupart des véhicules, les feux sont actionnés depuis les commandes du volant. Distinguer le symbole parmi ceux qui peuvent s'afficher sur le tableau de bord.
</p>              
    </div>
    <div>
    <h5>11. Vérifiez la présence du constat amiable.</h5>
    <p>Ce document n’est pas obligatoire, cependant, il est vivement conseillé de l’avoir constamment à bord du véhicule en cas d’accident. En effet, il permet aux conducteurs impliqués dans un cas d’accident de décrire les faits pour que l’assurance établisse les responsabilités de chacun. Comme pour la carte grise et l'assurance du véhicule, demandez à votre enseignant avant l'examen, où se trouve ce document.</p>              
    </div>
    <div>
    <h5>12. Montrez sur le tableau de bord l'indicateur permettant de contrôler le niveau de carburant.</h5>
    <p>Le voyant s’allume seulement lorsque le niveau de carburant devient faible. Si vous tombez sur cette question alors que le niveau de carburant n'est pas faible, il suffit de couper le moteur et de le rallumer en tournant la clé pour que celui-ci s'allume quelques secondes.</p>              
    </div>
    </div>
         </div>   
       </div>
    </section>
  )
}

export default Check_internal