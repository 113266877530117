import React, { useEffect } from "react";

function MessageList({ messages, userId, socket, setMessages }) {
  useEffect(() => {
    if (!socket) return;

    // Listen for new messages from the WebSocket
    socket.on("newMessage", (newMessage) => {
      console.log("New message received:", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    // Cleanup the socket listener on component unmount
    return () => {
      socket.off("newMessage");
    };
  }, [socket, setMessages]);

  return (
    <div className="message-list">
      {messages.map((message, index) => (
      
        <div
        key={`${message.id}-${index}`}
          className={`message ${
            message.senderId === parseInt(userId) ? "sent" : "received"
          }`}
        >
          <span>{message.content}</span>
          <div className="timestamp">
            {new Date(message.createdAt).toLocaleTimeString()}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MessageList;
