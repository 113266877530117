import React from 'react'
import './style.css'
import floro_user from '../images/floro_user.png'
import { useNavigate } from 'react-router-dom';

function Myevaluationdetail() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
    <section className='evaluation_details'>
        <div className='container'>
            <div className='wrapping'>
            <button type="button" onClick={goBack} class="btn btn-secondary btn-main">   <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8075 9.06814C16.8105 9.29314 16.724 9.51012 16.5671 9.67135C16.4101 9.83258 16.1955 9.92484 15.9705 9.92786L4.44409 10.0821L7.562 13.1177C7.71401 13.2765 7.79843 13.4881 7.7975 13.708C7.79656 13.9278 7.71033 14.1387 7.55698 14.2962C7.40363 14.4537 7.19512 14.5455 6.97539 14.5524C6.75566 14.5592 6.54186 14.4805 6.37903 14.3328L1.79387 9.86872C1.63287 9.71178 1.54075 9.49735 1.53774 9.27253C1.53473 9.04772 1.62108 8.8309 1.77782 8.66971L6.24185 4.08455C6.40067 3.93254 6.61228 3.84811 6.83212 3.84904C7.05195 3.84998 7.26284 3.93621 7.42036 4.08956C7.57787 4.24292 7.66971 4.45142 7.67653 4.67116C7.68335 4.89089 7.60462 5.10469 7.45692 5.26751L4.42138 8.38542L15.9478 8.23113C16.1728 8.22812 16.3898 8.31461 16.551 8.47158C16.7122 8.62855 16.8045 8.84314 16.8075 9.06814Z" fill="white"/>
            </svg>Retour</button>
            <h3>Mes évaluations</h3>
            <div className='eva-details'>
               <div className='d-flex justify-content-between top-eva'>
               <div className='d-flex align-items-center'>
                <img src={floro_user} />
                <div className='ms-2'>
                <p className='mb-0'>Florencia D</p>
                <span>instructrice</span>
                </div>
                </div>
              <div className='dateandtime'>
              <strong>Date et heure</strong><br></br>
                <span>15 july, 13h00</span>
              </div>
               </div>
               <hr></hr>
               <div className='evaluation-list'>
                   <div className='pts'>
                      <div className='d-flex align-items-start'>
                      <div className='nu-cont'>1. </div>
                       <div>
                           <h5>Contrôle des véhicules</h5>
                           <p className='mb-0'><strong>Commentaire:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                       </div>
                      </div>
                     <div className='d-flex'>
                     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4707 9.61085L16.2495 13.3866L17.5355 19.0332C17.6065 19.3397 17.5882 19.6612 17.483 19.9569C17.3778 20.2527 17.1904 20.5095 16.9445 20.6949C16.6986 20.8804 16.4052 20.9861 16.1013 20.9987C15.7974 21.0114 15.4968 20.9304 15.2373 20.766L10.5002 17.7438L5.76024 20.766C5.50081 20.9294 5.20052 21.0096 4.89719 20.9965C4.59385 20.9834 4.30105 20.8776 4.05563 20.6923C3.81022 20.5071 3.62317 20.2507 3.51804 19.9554C3.41291 19.6602 3.3944 19.3393 3.46485 19.0332L4.7556 13.3866L0.534393 9.61085C0.304851 9.4052 0.138843 9.13401 0.057099 8.83115C-0.0246446 8.52828 -0.0185002 8.20716 0.0747648 7.90789C0.16803 7.60863 0.344282 7.34447 0.58151 7.14842C0.818738 6.95237 1.10643 6.83311 1.40865 6.80554L6.94312 6.34269L9.07811 0.986829C9.19367 0.694938 9.39036 0.445261 9.64316 0.269542C9.89597 0.0938237 10.1935 0 10.4978 0C10.8022 0 11.0997 0.0938237 11.3525 0.269542C11.6053 0.445261 11.802 0.694938 11.9176 0.986829L14.0516 6.34269L19.5861 6.80554C19.8889 6.83209 20.1775 6.95068 20.4155 7.14646C20.6536 7.34224 20.8307 7.6065 20.9245 7.90613C21.0184 8.20576 21.0248 8.52744 20.9431 8.83086C20.8614 9.13428 20.6951 9.40594 20.465 9.61182L20.4707 9.61085Z" fill="#FFA800"/>
</svg>
<strong className='rates'>4.5</strong>
                     </div>
                   </div>
                   <div className='pts'>
                      <div className='d-flex align-items-start'>
                      <div className='nu-cont'>2. </div>
                       <div>
                           <h5>Respect du code de la route</h5>
                           <p className='mb-0'><strong>Commentaire:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                       </div>
                      </div>
                     <div className='d-flex'>
                     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4707 9.61085L16.2495 13.3866L17.5355 19.0332C17.6065 19.3397 17.5882 19.6612 17.483 19.9569C17.3778 20.2527 17.1904 20.5095 16.9445 20.6949C16.6986 20.8804 16.4052 20.9861 16.1013 20.9987C15.7974 21.0114 15.4968 20.9304 15.2373 20.766L10.5002 17.7438L5.76024 20.766C5.50081 20.9294 5.20052 21.0096 4.89719 20.9965C4.59385 20.9834 4.30105 20.8776 4.05563 20.6923C3.81022 20.5071 3.62317 20.2507 3.51804 19.9554C3.41291 19.6602 3.3944 19.3393 3.46485 19.0332L4.7556 13.3866L0.534393 9.61085C0.304851 9.4052 0.138843 9.13401 0.057099 8.83115C-0.0246446 8.52828 -0.0185002 8.20716 0.0747648 7.90789C0.16803 7.60863 0.344282 7.34447 0.58151 7.14842C0.818738 6.95237 1.10643 6.83311 1.40865 6.80554L6.94312 6.34269L9.07811 0.986829C9.19367 0.694938 9.39036 0.445261 9.64316 0.269542C9.89597 0.0938237 10.1935 0 10.4978 0C10.8022 0 11.0997 0.0938237 11.3525 0.269542C11.6053 0.445261 11.802 0.694938 11.9176 0.986829L14.0516 6.34269L19.5861 6.80554C19.8889 6.83209 20.1775 6.95068 20.4155 7.14646C20.6536 7.34224 20.8307 7.6065 20.9245 7.90613C21.0184 8.20576 21.0248 8.52744 20.9431 8.83086C20.8614 9.13428 20.6951 9.40594 20.465 9.61182L20.4707 9.61085Z" fill="#FFA800"/>
</svg>
<strong className='rates'>4.5</strong>
                     </div>
                   </div>
                   <div className='pts'>
                      <div className='d-flex align-items-start'>
                      <div className='nu-cont'>3. </div>
                       <div>
                           <h5>Réactions et réflexes</h5>
                           <p className='mb-0'><strong>Commentaire:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                       </div>
                      </div>
                     <div className='d-flex'>
                     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4707 9.61085L16.2495 13.3866L17.5355 19.0332C17.6065 19.3397 17.5882 19.6612 17.483 19.9569C17.3778 20.2527 17.1904 20.5095 16.9445 20.6949C16.6986 20.8804 16.4052 20.9861 16.1013 20.9987C15.7974 21.0114 15.4968 20.9304 15.2373 20.766L10.5002 17.7438L5.76024 20.766C5.50081 20.9294 5.20052 21.0096 4.89719 20.9965C4.59385 20.9834 4.30105 20.8776 4.05563 20.6923C3.81022 20.5071 3.62317 20.2507 3.51804 19.9554C3.41291 19.6602 3.3944 19.3393 3.46485 19.0332L4.7556 13.3866L0.534393 9.61085C0.304851 9.4052 0.138843 9.13401 0.057099 8.83115C-0.0246446 8.52828 -0.0185002 8.20716 0.0747648 7.90789C0.16803 7.60863 0.344282 7.34447 0.58151 7.14842C0.818738 6.95237 1.10643 6.83311 1.40865 6.80554L6.94312 6.34269L9.07811 0.986829C9.19367 0.694938 9.39036 0.445261 9.64316 0.269542C9.89597 0.0938237 10.1935 0 10.4978 0C10.8022 0 11.0997 0.0938237 11.3525 0.269542C11.6053 0.445261 11.802 0.694938 11.9176 0.986829L14.0516 6.34269L19.5861 6.80554C19.8889 6.83209 20.1775 6.95068 20.4155 7.14646C20.6536 7.34224 20.8307 7.6065 20.9245 7.90613C21.0184 8.20576 21.0248 8.52744 20.9431 8.83086C20.8614 9.13428 20.6951 9.40594 20.465 9.61182L20.4707 9.61085Z" fill="#FFA800"/>
</svg>
<strong className='rates'>4.5</strong>
                     </div>
                   </div>
                   <div className='pts'>
                      <div className='d-flex align-items-start'>
                      <div className='nu-cont'>4. </div>
                       <div>
                           <h5>Confiance et attitude</h5>
                           <p className='mb-0'><strong>Commentaire:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                       </div>
                      </div>
                     <div className='d-flex'>
                     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4707 9.61085L16.2495 13.3866L17.5355 19.0332C17.6065 19.3397 17.5882 19.6612 17.483 19.9569C17.3778 20.2527 17.1904 20.5095 16.9445 20.6949C16.6986 20.8804 16.4052 20.9861 16.1013 20.9987C15.7974 21.0114 15.4968 20.9304 15.2373 20.766L10.5002 17.7438L5.76024 20.766C5.50081 20.9294 5.20052 21.0096 4.89719 20.9965C4.59385 20.9834 4.30105 20.8776 4.05563 20.6923C3.81022 20.5071 3.62317 20.2507 3.51804 19.9554C3.41291 19.6602 3.3944 19.3393 3.46485 19.0332L4.7556 13.3866L0.534393 9.61085C0.304851 9.4052 0.138843 9.13401 0.057099 8.83115C-0.0246446 8.52828 -0.0185002 8.20716 0.0747648 7.90789C0.16803 7.60863 0.344282 7.34447 0.58151 7.14842C0.818738 6.95237 1.10643 6.83311 1.40865 6.80554L6.94312 6.34269L9.07811 0.986829C9.19367 0.694938 9.39036 0.445261 9.64316 0.269542C9.89597 0.0938237 10.1935 0 10.4978 0C10.8022 0 11.0997 0.0938237 11.3525 0.269542C11.6053 0.445261 11.802 0.694938 11.9176 0.986829L14.0516 6.34269L19.5861 6.80554C19.8889 6.83209 20.1775 6.95068 20.4155 7.14646C20.6536 7.34224 20.8307 7.6065 20.9245 7.90613C21.0184 8.20576 21.0248 8.52744 20.9431 8.83086C20.8614 9.13428 20.6951 9.40594 20.465 9.61182L20.4707 9.61085Z" fill="#FFA800"/>
</svg>
<strong className='rates'>4.5</strong>
                     </div>
                   </div>
               </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Myevaluationdetail