import React from 'react'

function Star({ selected, onClick }) {
  return (
    <span
    onClick={onClick}
    style={{
      cursor: "pointer",
      color: selected ? "#FFA800" : "#ccc",
      fontSize: "24px",
      marginRight: "5px",
    }}
  >
    ★
  </span>
  )
}

export default Star