import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import teacherimg from "../images/teacher-img.png";
import ion_calendar from "../images/ion_calendar-outline.svg"
import condutc_ico from "../images/condutc_ico.svg"
import iconamoon_close from "../images/iconamoon_close.png"
import gear from "../images/gear.svg"
import "./style.css";

function New_reservation_update() {
  return (
    <section className="new-reservation-update">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb">
          <Link to="/dashboard">Acceuil</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Récapitulatif</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="your-teacher">
              <h3>Votre enseignant</h3>
              <div className="tteacher">
                <img src={teacherimg} />
                <div className="ms-3">
                  <h4>Abdelaziz S.</h4>
                  <div className="lessons">
                    <p className="mb-0">
                      <strong>+ 200 </strong>leçons
                    </p>
                  </div>
                </div>
              </div>
              <h4 className="my-4">Se rendre au lieu de rendez-vous</h4>
              <div className="address_details">
                <div className="address_place">
                  <strong>Adresse : </strong>
                  <div className="d-flex justify-content-between">
                    <p>22 Avenue Bernard Hirsch, Cergy</p>
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.0583 25.8333H12.4C12.1945 25.8333 11.9973 25.7517 11.852 25.6063C11.7067 25.461 11.625 25.2639 11.625 25.0583V12.4C11.625 12.1945 11.7067 11.9973 11.852 11.852C11.9973 11.7067 12.1945 11.625 12.4 11.625H25.0583C25.2639 11.625 25.461 11.7067 25.6063 11.852C25.7517 11.9973 25.8333 12.1945 25.8333 12.4V25.0583C25.8333 25.2639 25.7517 25.461 25.6063 25.6063C25.461 25.7517 25.2639 25.8333 25.0583 25.8333Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.3748 11.6251V5.94175C19.3748 5.73621 19.2932 5.53908 19.1478 5.39374C19.0025 5.2484 18.8054 5.16675 18.5998 5.16675H5.9415C5.73596 5.16675 5.53884 5.2484 5.3935 5.39374C5.24816 5.53908 5.1665 5.73621 5.1665 5.94175V18.6001C5.1665 18.8056 5.24816 19.0027 5.3935 19.1481C5.53884 19.2934 5.73596 19.3751 5.9415 19.3751H11.6248"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="address_place">
                  <strong>Secteur: </strong>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">Gare de Cergy Préfecture</p>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
          <h3>Votre leçon</h3>
              <div className="leson_detail">
               
                <div className="your_lesson">
                    <img src={ion_calendar} />
                    <strong>Mercredi 21 août</strong>
                </div>
                <div className="conduct">
                   <div className="d-flex">
                        <img src={condutc_ico} className="me-2" />
                        <span>Conduite</span>
                   </div>
                   <div className="d-flex">
                       <hr className="vertical-divi"></hr>
                      <div>
                      <div className="tyme">
                           <span>10:00 - 11:00</span>
                           <img src={iconamoon_close} />
                       </div>
                       <p className="m-0">Ajouter une heure</p>
                      </div>
                   </div>
                </div>
              </div>
              <h4 className="my-3">Votre véhicule</h4>
              <div className="description_lesson">
                <h5>Description</h5>
                <div className="d-flex justify-content-between">
                    <span className="car">Renault
                    Clio Iv</span>
                    <div className="manual-type">
                        <img src={gear} />
                        <span>Boite manuelle</span>
                    </div>
                </div>
              </div>
             <div className="mt-4 text-end">
             <button type="button" class="btn btn-secondary btn-main">Acheter des crédits</button>
             </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default New_reservation_update;
