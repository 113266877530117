import React from 'react'
import './style.css'
import calendr from '../images/calendr.svg'
import instman from '../images/inst-man.png'
import starrate from '../images/star-rate.svg'
import neweva from '../images/new-eva.svg'
import Pagination from 'react-bootstrap/Pagination';
import { Link, useNavigate } from 'react-router-dom';

function Myevaluation() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
   <section className='evaluation'>
       <div className='container'>
           <div className='wrapping'>
         
           <div className='d-flex justify-content-between align-items-center'>
           
            <h3>Mes évaluations</h3>
            <button type="button" onClick={goBack} class="btn btn-secondary btn-main">   <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8075 9.06814C16.8105 9.29314 16.724 9.51012 16.5671 9.67135C16.4101 9.83258 16.1955 9.92484 15.9705 9.92786L4.44409 10.0821L7.562 13.1177C7.71401 13.2765 7.79843 13.4881 7.7975 13.708C7.79656 13.9278 7.71033 14.1387 7.55698 14.2962C7.40363 14.4537 7.19512 14.5455 6.97539 14.5524C6.75566 14.5592 6.54186 14.4805 6.37903 14.3328L1.79387 9.86872C1.63287 9.71178 1.54075 9.49735 1.53774 9.27253C1.53473 9.04772 1.62108 8.8309 1.77782 8.66971L6.24185 4.08455C6.40067 3.93254 6.61228 3.84811 6.83212 3.84904C7.05195 3.84998 7.26284 3.93621 7.42036 4.08956C7.57787 4.24292 7.66971 4.45142 7.67653 4.67116C7.68335 4.89089 7.60462 5.10469 7.45692 5.26751L4.42138 8.38542L15.9478 8.23113C16.1728 8.22812 16.3898 8.31461 16.551 8.47158C16.7122 8.62855 16.8045 8.84314 16.8075 9.06814Z" fill="white"/>
            </svg>Retour</button>
           </div>
            <div className='fils'>
                <strong>Filters</strong>
                <div className='date'>
                    <span>Date: 03/06/24</span>
                    <img src={calendr} />
                </div>
            </div>
            <div className='eva-time table-responsive'>
            <table class="table">
  <thead>
    <tr>
      <th scope="col">S.non</th>
      <th scope="col">Nom</th>
      <th scope="col">Rôle</th>
      <th scope="col">Date et heure</th>
      <th scope="col">Note globale</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr className='newevaluation'>
      <th scope="row"> <img src={neweva} /> 01</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><Link to="/myevaluation-details"><button type="button" class="btn btn-secondary btn-main">Voir plus</button></Link></td>
    </tr>
    <tr>
      <th scope="row">02</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><button type="button" class="btn btn-secondary btn-main">Voir plus</button></td>
    </tr>
    <tr>
      <th scope="row">03</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><button type="button" class="btn btn-secondary btn-main">Voir plus</button></td>
    </tr>
    <tr>
      <th scope="row">04</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><button type="button" class="btn btn-secondary btn-main">Voir plus</button></td>
    </tr>
    <tr>
      <th scope="row">05</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><button type="button" class="btn btn-secondary btn-main">Voir plus</button></td>
    </tr>
    <tr>
      <th scope="row">06</th>
      <td> <img src={instman} /> Florencia D</td>
      <td>Étudiante</td>
      <td>15 July, 1:00 PM</td>
      <td> <img src={starrate} /> 4.5</td>
      <td><button type="button" class="btn btn-secondary btn-main">Voir plus</button></td>
    </tr>
   
  </tbody>
</table>
            </div>
            <Pagination className='mb-0'>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <Pagination.Item>{2}</Pagination.Item>
      <Pagination.Item>{3}</Pagination.Item>
      <Pagination.Item active>{4}</Pagination.Item>
      <Pagination.Item>{5}</Pagination.Item>
      <Pagination.Item>{6}</Pagination.Item>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>
           </div>
      
       </div>
   </section>
  )
}

export default Myevaluation