// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-recent {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cecece;
    padding: 13px 0;
}
section.notifications .wrapping{
    padding: 13px;
}
section.notifications h4 {
    font-family: 'poppins';
}
span.linktoshow {
    color: #c43cab;
    cursor: pointer;
}
.fade.inst-feedback .modal-dialog{
    max-width: 600px;
    width: 100%;
}
@media (max-width: 460px){
    .noti-recent img {
        width: 42px;
        height: 42px;
    }
    .new-noti img {
        width: 10px;
    }
    section.notifications hr{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Notifications/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;IAChC,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,WAAW;IACf;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".noti-recent {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid #cecece;\n    padding: 13px 0;\n}\nsection.notifications .wrapping{\n    padding: 13px;\n}\nsection.notifications h4 {\n    font-family: 'poppins';\n}\nspan.linktoshow {\n    color: #c43cab;\n    cursor: pointer;\n}\n.fade.inst-feedback .modal-dialog{\n    max-width: 600px;\n    width: 100%;\n}\n@media (max-width: 460px){\n    .noti-recent img {\n        width: 42px;\n        height: 42px;\n    }\n    .new-noti img {\n        width: 10px;\n    }\n    section.notifications hr{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
