import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function Progress_chart() {
    const [chartData] = useState({
        series: [
          {
            name: 'Cours terminé',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: 'vos cours de pilotage progressent',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
         
        ],
       

        options: {
            colors: ['#C43CAB', '#CACACA'],
          chart: {
            type: 'bar',
            height: 250,
          },
          
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '35%',
              endingShape: 'rounded',
              
            },
            
          },
          dataLabels: {
            enabled: false,
            
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          },
         
          fill: {
            opacity: 1,
            colors: ['#C43CAB', '#CACACA']
          },
          tooltip: {
            y: {
              formatter: (val) => ` ${val} %`,
              
            },
            
          },
        },
      });
  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={250} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default Progress_chart