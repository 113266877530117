import React from 'react'
import  "../style.css"
import prem_icon from "../../images/prem-icon.png"
import { useNavigate } from 'react-router-dom';


function Premiers_secours() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
    <section className='premiers'>
        <div className='container'>
        <h4>Préparez-vous aux questions de l'examen</h4>
        <div className='back-btn mt-3'>
        <button type="button" onClick={goBack} class="btn btn-secondary btn-main"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white"/>
</svg>
 Retour</button>
        </div>
        <div className='text-wraps'>
                <div className='top-title'>
                    <img src={prem_icon} />
                    <h4>Premiers Secours</h4>
                </div>
                <p>A chaque question de vérification est liée à une question de sécurité routière. Chaque question rapportera 1 point au candidat. L’objectif principal de ces vérifications est de sensibiliser le candidat sur les dangers de la route, lui donner les bons comportements et réflexes à adopter pour réduire l’accidentalité sur les routes.</p>
                <hr className='divide-line'></hr>
                <h4>Protéger</h4>
                <div className='questions'>
                   <div>
                   <h5>1. Comment et pourquoi protéger une zone de danger en cas d'accident ?</h5>
                    <p>En délimitant clairement et largement la zone de danger de façon visible pour protéger les victimes et éviter un sur-accident.</p>
                   </div>
                   <div>
                   <h5>2. En cas de panne ou d'accident, quel équipement de sécurité doit être porté avant de quitter le véhicule ?</h5>
                    <p>Il faut porter le gilet haute visibilité avant de sortir du véhicule.</p>
                   </div>
                   <div>
                   <h5>3. Hors autoroute ou endroits dangereux, en cas de panne ou d'accident, où doit être placé le triangle de présignalisation ?</h5>
                    <p>Le triangle de présignalisation doit être placé à une distance d’environ 30 m de l’accident ou avant un virage ou un sommet de côte.</p>
                   </div>
                   <div>
                   <h5>4. Dans quelle situation peut-on déplacer une victime ?</h5>
                    <p>En présence d'un danger réel, immédiat non contrôlable. Ce déplacement doit rester exceptionnel.</p>
                   </div>
                   <div>
                   <h5>5. Quelles sont les conditions pour réaliser le déclenchement d’urgence d’une victime en présence d’un danger réel, immédiat et non contrôlable ?</h5>
                    <p>La victime doit être visible, facile à atteindre et rien ne doit générer son dégagement.. Il faut être sûr de pouvoir réaliser le dégagement de la victime.</p>
                   </div>
                   <div>
                   <h5>6. Si un dégagement d’urgence de la victime est nécessaire, ou doit-elle être déplacée ?</h5>
                    <p>Dans un endroit suffisamment éloigné du danger et de ses conséquences.</p>
                   </div>
                   
                </div>
                <h4>Alerteur</h4>
                <div className='questions'>
                   <div>
                   <h5>1. Par quels moyens doit être réalisé l’alerte des secours ?</h5>
                    <p>L’alerte doit être donnée à l’aide d’un téléphone portable ou à défaut d’un téléphone fixe ou d’une borne d’appel d’urgence.</p>
                   </div>
                   <div>
                   <h5>2. Pourquoi l’alerte auprès des services de secours doit-elle être précise et rapide ?</h5>
                    <p>Afin de permettre aux services de secours d’apporter les moyens adaptés aux victimes dans le délai le plus court.</p>
                   </div>
                   <div>
                   <h5>3. Quelles sont les trois informations à transmettre aux services de secours ?</h5>
                    <p>Le numéro de téléphone à partir duquel l’appel est émis, la nature et la localisation la plus précise du problème.</p>
                   </div>
                   <div>
                   <h5>4. Quelles sont les trois informations à transmettre aux services de secours ?</h5>
                    <p>Le numéro de téléphone à partir duquel l’appel est émis, la nature et la localisation la plus précise du problème.</p>
                   </div>
                  
                </div>
        </div>
        </div>
    </section>
  )
}

export default Premiers_secours