import React from 'react'
import './style.css'
import reportadmin from '../images/report-admin.svg'
import back_arrows from '../images/back-arrows.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

function Reportby_admin() {
  const navigate = useNavigate();

  const goBack = () => {
      navigate(-1); // Navigate back to the previous page
  };
  return (
   <section className='report-admin'>
       <div className='container'>
        <div className='wrapping'>
        <div className='d-flex justify-content-between'>
          
          <button type="button" onClick={goBack} class="btn btn-secondary btn-main"><img src={back_arrows} /> Retour</button>
         </div>
     <p className='recent-share'> <span>Récente</span></p>
      <div className='report'>
         <div className='d-flex justify-content-start'>
         <img src={reportadmin} />
          <div className='ms-3'>
              <h4>Rapport sur la qualité du travail créé par l'administrateur</h4>
              <p>10 juin 2024 . <span>il y a 1 minute</span></p>
          </div>
         </div>
         <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic" className='btn-main'>
      Exporter
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">PDF</Dropdown.Item>
        <Dropdown.Item href="#/action-2">CSV</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Txt</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      </div>
      <p className='recent-share'> <span>La semaine dernière</span></p>
      <div className='report'>
         <div className='d-flex justify-content-start'>
         <img src={reportadmin} />
          <div className='ms-3'>
              <h4>Rapport sur la qualité du travail créé par l'administrateur</h4>
              <p>10 juin 2024 . <span>il y a 1 minute</span></p>
          </div>
         </div>
         <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic" className='btn-main'>
      Exporter
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">PDF</Dropdown.Item>
        <Dropdown.Item href="#/action-2">CSV</Dropdown.Item>
        <Dropdown.Item href="#/action-3">text</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      </div>
        </div>
       </div>
   </section>
  )
}

export default Reportby_admin