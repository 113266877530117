import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import permisimg from '../../images/permis-img.png' 
import "../style.css"


function Passerpermis() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard'); // Adjust the route to match your dashboard route
  };
  return (
    // this section is for unsubscribed user

    // <section className='passerpermis'>
    //        <div className='permisbg '>
    //         <div className=' '>
    //             <div className='wraping'>
    //                 <h2>explorez les plans et choisissez de commencer la leçon de conduite</h2>
                   
    //                 <Link to="/newreservation"><button type="button" class="btn btn-secondary border-0 bg-white">Acheter maintenant</button></Link>
    //             </div>
    //         </div>
           
    //     </div>
    // </section>
    <section className='passerle'>
      <div className='container'>
        <div className='top-line'>
          <h5>Votre formation à la conduite est en cours</h5>

        </div>
        <div className='row permis'>
          <div className='col-lg-5'>
              <img src={permisimg} />
          </div>
          <div className='col-lg-7'>
             <div className='permis-side-content'>
             <h3>Avancée dans le livret d’apprentissage</h3>
              <p>Pour passer l’examen du permis, un de vos enseignants doit vous déclarer prêt(e). N’hésitez pas à demander à vos enseignants où est-ce que vous en êtes dans votre progression. Les enseignants sont les mieux placés pour vous donner une réponse.</p>
             <Link to="/boutique"> <button 
                type="button" 
                className="btn btn-light"
                onClick={handleNavigate}
              >
                Je réserve une leçon
              </button></Link>
             </div>
            </div>
        </div>
        <h2 className='mb-4 mt-5'><strong>Récapitulatif</strong></h2>
        <div className='summary-table table-responsive'>
            <table class="table">
                <thead>
                    <tr>
                    <th scope="col">Résultat</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Score</th>
                    <th scope="col">Date</th>
                    <th scope="col">Centre d'examen</th>
                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className='text-success'>Pass</td>
                    <td><p className='mb-0 '>Close</p></td>
                    <td>60</td>
                    <td>12 Oct, 2023</td>
                    <td>21 Avenue Hoche, Bobigny, Île-de-France
                    </td>
                    
                    </tr>
                    <tr>
                    <td className='text-danger'>Fail</td>
                    <td><p className='mb-0 '>Close</p></td>
                    <td>60</td>
                    <td>12 Oct, 2023</td>
                    <td>21 Avenue Hoche, Bobigny, Île-de-France
                    </td>
                    
                    </tr>

                </tbody>
                </table>
           </div>
      </div>
    </section> 
  )
}

export default Passerpermis