// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-window .btn-primary {
  background-image: linear-gradient(to right, #c43cab, #f87e4b);
  border: 0;
  border-radius: 26px;
  font-size: 12px;
}

.map-window {
  padding: 0 0.5rem;
}
.lesson-count {
  background-image: linear-gradient(to right, #D091FF, #FFD99F);
  padding: 4px;
  border-radius: 3px;
  font-weight: 600;
}
button.gm-ui-hover-effect {
  display: none !important;
}
div#\\39 64D6C5A-1BF1-4822-98E2-1C23D9A6B59D {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;AACf","sourcesContent":[".map-window .btn-primary {\n  background-image: linear-gradient(to right, #c43cab, #f87e4b);\n  border: 0;\n  border-radius: 26px;\n  font-size: 12px;\n}\n\n.map-window {\n  padding: 0 0.5rem;\n}\n.lesson-count {\n  background-image: linear-gradient(to right, #D091FF, #FFD99F);\n  padding: 4px;\n  border-radius: 3px;\n  font-weight: 600;\n}\nbutton.gm-ui-hover-effect {\n  display: none !important;\n}\ndiv#\\39 64D6C5A-1BF1-4822-98E2-1C23D9A6B59D {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
