import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Na_Dec_06_ai from '../images/Na_Dec_06-ai 1.png';
import './style.css';

function Successful() {
  const navigate = useNavigate(); 

  const handleGoHome = () => {
    navigate('/dashboard'); 
  };

  return (
    <section className='successfull'>


      <div className='container-fluid'>
        <div className='transation-successful'>
          <h2>Transaction réussie !</h2>
          <img src={Na_Dec_06_ai} alt="Transaction Successful" />
          <p>Votre paiement a été traité avec succès</p>
          <button 
            type="button" 
            className="btn btn-secondary btn-main"
            onClick={handleGoHome} 
          >
            Retour
          </button>

        </div>
      </div>
    </section>
  );
}

export default Successful;
