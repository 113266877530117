import React from 'react';
import { Navigate } from 'react-router-dom';

const Guard = ({ children }) => {
  const token = localStorage.getItem('token'); 
  const isAuthenticated = !!token; 
  //const userHasAdminRole = true; 

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default Guard;
