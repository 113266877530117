// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Group 1000005287.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.confimation{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    width: 100%;
}
section.confimation h3 {
    text-align: center;
    font-size: 40px;
}
section.confimation {
    text-align: center;
}
section.confimation p {
    font-size: 21px;
}
section.confimation button {
    margin-top: 37px;
    margin-bottom: 40px;
}
section.confimation {
    padding: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Confirmation/style.css"],"names":[],"mappings":"AAAA;IACI,yDAAuD;IACvD,4BAA4B;IAC5B,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB","sourcesContent":["section.confimation{\n    background-image: url('../images/Group 1000005287.png');\n    background-repeat: no-repeat;\n    width: 100%;\n}\nsection.confimation h3 {\n    text-align: center;\n    font-size: 40px;\n}\nsection.confimation {\n    text-align: center;\n}\nsection.confimation p {\n    font-size: 21px;\n}\nsection.confimation button {\n    margin-top: 37px;\n    margin-bottom: 40px;\n}\nsection.confimation {\n    padding: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
