// CourseCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import msico from '../../../images/ms-ico.svg'

const CourseCard = ({ skill, index, studentId }) => {
    const progressPercentage = skill.totalProgress !== null ? skill.totalProgress : 0;
    const progressPosition = `${progressPercentage - 3}%`;

    // Determine if we should show the circle
    const showCircle = progressPercentage > 0;

    return (
        <div className='col-lg-6'>
            <Link to={`/skills/${skill.id}`}> {/* Link to specific course */}
                <div className='masters-guides'>
                    <div className='d-flex align-items-start justify-content-between'>
                        <div className='count_no'>
                            <span>{index + 1}</span> {/* Display index + 1 for numbering */}
                        </div>
                        <h4>{skill.name}</h4> {/* Dynamic course title */}
                        <img src={msico} alt="icon" />
                    </div>
                    <div className="progress">
                        <div
                            className={`progress-bar ${showCircle ? 'show-circle' : ''}`}
                            role="progressbar"
                            aria-valuenow={skill.totalProgress !== null ? skill.totalProgress : 0}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                                width: `${progressPercentage}%`, // Dynamic width
                                '--progress-position': progressPosition // Set CSS variable
                            }}// Dynamic width
                        >
                            {skill.totalProgress !== null ? skill.totalProgress : 0}% {/* Display progress percentage */}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CourseCard;
