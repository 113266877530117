import React, { useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import sent from "../images/sent.svg";
import mage_attachment from "../images/mage_attachment.svg";
import axiosInstance from "../utils/http/config/axios";

function MessageInput({ socket, userId, receiver }) {
  console.log(socket);
  const [inputValue, setInputValue] = useState("");

  const addMessage = async (newMessage) => {
    try {
      const response = await axiosInstance.post(
        "/api/messages/send",
        newMessage
      ); // Replace with your actual API endpoint
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);
    console.log(receiver);
    if (inputValue.trim()) {
      const newMessage = {
        content: inputValue,
        receiverId: receiver,
        senderId: parseInt(userId),
        fileUrl: "",
      };
      // Send the message via REST API
      addMessage(newMessage);

      console.log(socket);
      // Send the message via WebSocket
      socket.emit("sendMessage", newMessage);

      setInputValue("");
    }
  };

  return (
    <div className="message-input">
      <div className="text_input">
        <Form.Control
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type a message..."
        />
        <div className="attchmnts">
          <Link to="">
            <img src={mage_attachment} />
          </Link>
          <Link to="" onClick={handleSubmit}>
            <img src={sent} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MessageInput;
