import React, { useState, useEffect } from "react";
import "./style.css";
import "../global.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import blind from "../images/blind.svg";
import eye from "../images/ph_eye.svg";
import gmplogo from "../images/brand_logo.svg";
import imglogin from "../images/img-login.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(blind);
  const [error, setError] = useState("");
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage || ""
  );
  const navigate = useNavigate();

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? eye : blind);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axiosInstance.post("/api/students/login", {
        email,
        password,
      });

      const {
        token,
        firstName,
        lastName,
        role,
        hasSubscription,
        nephCode,
        dashboard,
        userId,
        createdAt: serverCreatedAt,
      } = response.data;

      localStorage.setItem("token", token);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("role", role);
      localStorage.setItem("dashboard", dashboard);
      localStorage.setItem("hasSubscription", hasSubscription);
      localStorage.setItem("nephCode", nephCode);
      localStorage.setItem("userId", userId);

      const createdAt = new Date(serverCreatedAt).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      localStorage.setItem("createdAt", createdAt);

      const loginTime = new Date().getTime();
      localStorage.setItem("loginTime", loginTime);

      navigate(dashboard);

      toast.success("Connexion réussie !", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "Une erreur inattendue s'est produite. Veuillez réessayer."
      );
      toast.error(
        error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.",
        {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        }
      );
    }
  };

  const clearCache = () => {
    if ("caches" in window) {
      caches
        .keys()
        .then((names) => names.forEach((name) => caches.delete(name)));
    }
  };

  useEffect(() => {
    const loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const sessionExpiration = new Date(
        parseInt(loginTime, 10) + 3600000
      ).getTime();
      const timeLeft = sessionExpiration - new Date().getTime();

      const timeoutId = setTimeout(() => {
        localStorage.clear();
        clearCache();
        toast.info("Votre session a expiré. Veuillez vous reconnecter.", {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        });
        navigate("/");
      }, timeLeft);

      return () => clearTimeout(timeoutId);
    }
  }, [navigate]);

  return (
    <section className="login">
      <div className="container-fluid mx-0">
        <div className="row">
          <div className="col-lg-6 p-0">
            <div className="wrap">
              <div className="login-content">
                <h3>Connexion</h3>
                <p>Connectez-vous à votre compte</p>
                <ToastContainer />
                {successMessage && (
                  <div className="alert alert-success">{successMessage}</div>
                )}
                {error && (
                  <div className="alert alert-danger error-message">
                    {error}
                  </div>
                )}
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3 password">
                    <input
                      type={type}
                      className="form-control password"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span
                      className="flex justify-around items-center psd-icon"
                      onClick={handleToggle}
                    >
                      <img src={icon} alt="Basculer la visibilité" />
                    </span>
                  </div>

                  <div className="d-flex justify-content-end">
                    <div>
                      <Link to="/verify-link" style={{ color: "red" }}>
                        réinitialisation
                      </Link>
                    </div>
                  </div>

                  <div className="login-btn">
                    <button type="submit" className="btn btn-primary btn-fill">
                      Se connecter
                    </button>
                  </div>

                  <p className="my-4">
                    Vous n'avez pas de compte ? Veuillez vous{" "}
                    <Link to="/register" style={{ color: "#F85E1E" }}>
                      inscrire
                    </Link>
                  </p>
                  <p className="my-4">
                    Vous n'êtes pas étudiant ?{" "}
                    <Link
                      to="https://app.gmp-auto-ecole.com/instructor"
                      style={{ color: "#F85E1E" }}
                    >
                      Espace instructeur
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 p-0">
            <div className="right-side-bg">
              <img className="side-img-login" src={imglogin} alt="Login" />
              <div className="toplogo">
                <img src={gmplogo} alt="Logo" />
              </div>
              <div className="img-btm-text">
                <p>Développez vos compétences de conduite</p>
                <h2>
                  Apprenez à conduire en toute <span>confiance</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
