import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import attach from "../images/mage_attachment.png";
import sendMessg from "../images/mingcute_send-fill.png";

function Chat() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // State to store messages
  const [messages, setMessages] = useState([
    {
      type: "instructor",
      text: "Bonjour, j'ai un problème avec mon paiement. Pouvez-vous m'aider ?",
    },
    {
      type: "support",
      text: "Bonjour ! Je serais ravie de vous aider. Pouvez-vous décrire le problème que vous rencontrez avec votre paiement ?",
    },
    {
      type: "instructor",
      text: "J'ai essayé d'effectuer un paiement hier, mais cela n'a pas fonctionné. L'argent a été déduit de mon compte bancaire, mais il n'apparaît pas sur mon compte chez vous.",
    },
  ]);

  // State to handle textarea input
  const [newMessage, setNewMessage] = useState("");

  // Function to handle message submission
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      // Add the new message to the messages array
      setMessages([
        ...messages,
        {
          type: "instructor",
          text: newMessage,
        },
      ]);

      // Clear the textarea
      setNewMessage("");
    }
  };

  return (
    <section className="Chat">
      <div className="container">
        <div className="row chatRect">
          <div className="row align-items-center">
            <div className="col-lg-6">
             <h3> <span className="textStyle"><strong>Discutez avec nous</strong></span></h3>
            </div>
            <div className="col-lg-6 text-lg-end">
              <div className="back-btn">
                <button
                  type="button"
                  onClick={goBack}
                  className="btn btn-secondary btn-main"
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                      fill="white"
                    />
                  </svg>
                  Retour
                </button>
              </div>
            </div>
          </div>

          <div className="mt-5 chatwrap">
            {messages.map((message, index) => (
              <div className="row" key={index}>
                <div className={`col-lg-${message.type === "support" ? 8 : 4}`}></div>
                <div className={`col-lg-${message.type === "support" ? 8 : 8}`}>
                  <p className={message.type === "support" ? "support-chat" : "instructor"}>
                    {message.text}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="row mt-5">
            <div className="position-relative">
              <textarea
                className="form-control no-resize styleTextAreaChat p-4"
                id="workTextArea"
                rows="4"
                placeholder="Tapez ici..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              ></textarea>
              <img src={attach} alt="Attach" className="icon-style attach-icon" />
              <img
                src={sendMessg}
                alt="Send"
                className="icon-style send-icon"
                onClick={handleSendMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;
