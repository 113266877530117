import axios from 'axios';
import { toast } from "react-toastify";



// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // Replace with your API base URL
    //   timeout: 10000, // Timeout after 10 seconds
});

// Request interceptor to add JWT token to headers
axiosInstance.interceptors.request.use(
    (config) => {
        // Get the JWT token from localStorage or other storage method
        const token = localStorage.getItem('token');

        // If the token exists, add it to the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code within the range of 2xx will trigger this function
        return response;
    },
    (error) => {
        // Any status codes outside the range of 2xx will trigger this function
        if (error.response) {
            // Handle specific error responses (e.g., 401, 403, etc.)
            switch (error.response.status) {
                case 400:
                    console.error('Bad Request - possibly a client-side issue.');
                    break;
                case 401:
                    console.error('Unauthorized - maybe the token is expired.');
                    // temporary solution
                    window.location = '/'
                    // Redirect to login or refresh token logic here
                    break;
                case 403:
                    console.error('Forbidden - you don’t have permission to access.');
                    break;
                case 404:
                    console.error('Not Found - the requested resource is unavailable.');
                    break;
                case 500:
                    console.error('Internal Server Error - server-side issue.');
                    toast(error.response.message || "Something went wrong ! try later");

                    break;
                // Handle other status codes as needed
                default:
                    console.error('An unexpected error occurred.');
            }
            // Add more error handling as needed
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from the server');
        } else {
            // Something else happened while setting up the request
            console.error('Error setting up request:', error.message);
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
