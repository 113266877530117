import React, { useEffect, useState } from "react";
import paid from "../images/paid.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Helper function to fetch transaction details by ID
const fetchTransactionDetails = async (id, token) => {
  try {
    const response = await fetch(`/api/students/transaction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        "Erreur lors de la récupération des détails de la transaction"
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des détails de la transaction:",
      error
    );
    return null;
  }
};

function Invoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [transaction, setTransaction] = useState(
    location.state?.transaction || null
  );
  const [loading, setLoading] = useState(!transaction);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!transaction) {
      const token = localStorage.getItem("token");
      if (token) {
        fetchTransactionDetails(id, token)
          .then((data) => {
            if (data) {
              setTransaction(data);
            } else {
              setError("Transaction non trouvée");
            }
            setLoading(false);
          })
          .catch((err) => {
            setError(
              "Erreur lors de la récupération des détails de la transaction"
            );
            setLoading(false);
          });
      } else {
        setError("Aucun jeton d'authentification trouvé");
        setLoading(false);
      }
    }
  }, [id, transaction]);

  const goBack = () => {
    navigate(-1);
  };
 // pdf download button function
 const downloadPDF = () => {
  const invoice = document.getElementById("invoice");
  html2canvas(invoice, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10; // Définir la marge à 10 mm
    // Calculer la largeur et la hauteur de l'image avec les marges
    const imgWidth = pageWidth - margin * 2; // Ajuster pour laisser une marge de chaque côté
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // Position avec la marge en haut
    const xPos = margin;
    const yPos = margin;
    pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
    pdf.save("facture.pdf");
  });
  
};
  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="invoice">
      <div className="container">
        <div className="wrapping mb-4">
          <div className="back-btn mb-3">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>

          <div className="invoice">
          <div className="invoice" id="invoice">
            <div className="d-flex align-items-center invoice-pai">
              <h3>Facture</h3>
              <div className="paid">
                <span>
                  <img src={paid} alt="paid" />{" "}
                  {transaction.status === "réussi" ? "Payé" : "En attente"}
                </span>
              </div>
            </div>

            <div className="row invoice-details">
              <div className="col-lg-6">
                <div>
                  <span>Émis le</span>
                  <p>
                    <strong>
                      {new Date(transaction.date).toLocaleDateString()}
                    </strong>
                  </p>
                </div>
                <div className="mb-4">
                  <span>Méthode de paiement</span>
                  <p>
                    <strong>{transaction.method}</strong>
                  </p>
                </div>
                <div>
                  <span>Plan</span>
                  <p>
                    <strong>{transaction.achat}</strong>
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div>
                  <span>Prix</span>
                  <p>
                    <strong>{transaction.prix} €</strong>
                  </p>
                </div>
                <div className="mb-4">
                  <span>Status</span>
                  <p className="mb-0">
                    <strong>{transaction.status}</strong>
                  </p>
                </div>
              </div>
            </div>

            <hr></hr>
            <h5>Éléments</h5>
            <div className="tables table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Montant total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{transaction.achat}</th>
                    <td>{transaction.prix} €</td>
                    <td>1</td>
                    <td>{transaction.prix} €</td>
                  </tr>
                  <tr>
                    <th scope="row">Total</th>
                    <td></td>
                    <td></td>
                    <td>{transaction.prix} €</td>
                  </tr>
                </tbody>
              </table>
            </div>

          
          </div>
          <div className="text-end mt-4">
            <button onClick={downloadPDF} type="button" className="btn btn-secondary btn-main">
              Télécharger le reçu
            </button>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invoice;
